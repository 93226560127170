import React, { useState } from 'react';
import styles from "./styles.module.scss";
import SessionSvg from "../../../../../public/images/session.svg";
import DropdownButton from 'app/components/DropdownButton';
import { useTranslation } from "react-i18next";
import ManageOfferingModal from '../../ManageOfferingModal';
import EditOfferingModal from '../../EditOfferingModal';
import OfferingActionModal from '../../OfferingActionModal';
import { RootState } from "typedefs";
import { useSelector } from "react-redux";
import Manage from "../../../../../public/images/product-manage.svg";
import Edit from "../../../../../public/images/product-edit.svg";
import Clone from "../../../../../public/images/product-clone.svg";
import Active from "../../../../../public/images/product-active.svg";
import { schedulingFetchPatch } from 'app/utils/request/scheduling/fetch';
import ToolTip from 'app/routes/Scheduling/ToolTip';
import ManageOfferingModalSubscription from '../../ManageOfferingModalSubscription';
import { useHistory } from "react-router-dom";
import { currency_symbol } from '../../NewOffering/utils';

export default function Offering({ product, dataRefresher, enabledIntegration }) {
  const { t } = useTranslation();
  const [offeringManageModal, setOfferingManageModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [activateModal, setActivateModal] = useState(false);
  const userId = useSelector((state: RootState) => state.user.self.id);
  const history = useHistory();
  const activateOrDeactivate = async () => {
    const productData = {
      active: !product.enabled,
      stripe_product_id: product.stripeProdId,
      coach_id: userId,
    };

    schedulingFetchPatch(`/stripe_products/${product.stripeProdId}`, productData)
      .then((data) => {
        if (!data || data.error) {
          throw new Error(data.error || 'Error updating product status');
        }
        activateHandler();
        dataRefresher();
      })
      .catch((error) => {
        console.error('Error updating product status:', error);
      });
  };

  const offeringManageHandler = () => {
    setOfferingManageModal((prev) => !prev);
  };

  const editHandler = () => {
    setEditModal((prev) => !prev);
  };
  const cloneHandler = () => {
    if (product?.productType === 'product') {
      history.push({
        pathname: "/payment/create",
        state: { product }
      });
    } else {
      history.push({
        pathname: "/subscription/create",
        state: { product }
      });
    }
  };
  const activateHandler = () => {
    setActivateModal((prev) => !prev);
  };

  const memberMenu = [
    {
      title: t("Manage"),
      onClick: () => {
        offeringManageHandler();
      },
      image: <img src={Manage} alt="manage" />
    },
    {
      title: t("Edit"),
      onClick: () => {
        editHandler();
      },
      image: <img src={Edit} alt="edit" />
    },
    {
      title: t("Clone"),
      onClick: () => {
        cloneHandler();
      },
      image: <img src={Clone} alt="clone" />
    },
    {
      title: product.enabled ? t("Deactivate") : t("Activate"),
      onClick: () => {
        activateHandler();
      },
      image: <img src={Active} alt="active" />
    }
  ];

  return (
    <>
      <div className={styles.product_card}>
        <div className={styles.product_header}>
          {product?.productType === 'product' ? <span className={`${product.enabled && enabledIntegration ? styles.product_color : styles.inactive_color} ${styles.product_label}`}>{t('PACKAGE')}</span> :
            <span className={`${product.enabled && enabledIntegration ? styles.subscription_color : styles.inactive_color} ${styles.subscription_label}`}>{t('SUBSCRIPTION')}</span>}
          {enabledIntegration ?
            (
              <DropdownButton closeOnClick btnContent={<i className="bullets" />}>
                {memberMenu.map((item) => (
                  <div key={item.title} className={styles.container}>
                    <button onClick={() => item.onClick()}>
                      {item.image}
                      <p>{item.title}</p>
                    </button>
                  </div>
                ))}
              </DropdownButton>
            ) : null}
        </div>
        <div className={styles.product_body}>
          <div className={styles.product_status}>
            <span className={product.enabled && enabledIntegration ? styles.active_indicator : styles.inactive_indicator}></span>
            <div className={styles.titleRow}>
              <div
                className={styles.truncatedText}
              >
                {product.title?.length > 18 ? `${product?.title?.substring(0, 18)}...` : product.title}
              </div>
              {product.title?.length > 18 && (
                <div className={styles.toolTipPosition}>
                  <ToolTip text={product.title} orientation="right" />
                </div>
              )}
            </div>
          </div>
          <div className={styles.product_info}>
            {product?.numberOfSessions ?
              (
                <span className={styles.product_session}>
                  <img src={SessionSvg} alt="tags" className={styles.card_icon} />
                  {product?.numberOfSessions === -1 ?
                    (
                      <div> &infin; {t('Sessions')}</div>
                    ) : t("count_sessions", { count: Number(product?.numberOfSessions) })}
                </span>
              ) : <span />}
            <div className={`${styles.product_details}`}>
              {product.description}
            </div>

          </div>
        </div>
        <hr className={styles.line} />

        <div className={styles.product_footer}>
          <div className={product.enabled && enabledIntegration ? styles.product_price : styles.product_price_inactive}>{currency_symbol[product.currency]}{product?.price}</div>
          <p className={styles.payment_info}>
            {product?.productType === 'product'
              ? t('One time payment')
              : product.recurring === '1'
                ? t('Billed monthly')
                : product.recurring === '12'
                  ? t('Billed annually')
                  : t('Billed every {{count}} months', { count: product.recurring })}
          </p>
        </div>
      </div>
      {offeringManageModal && (
        product.productType === 'subscription' ? <ManageOfferingModalSubscription product={product} isModalOpen={offeringManageModal} modalHandler={offeringManageHandler} /> :
          <ManageOfferingModal product={product} isModalOpen={offeringManageModal} modalHandler={offeringManageHandler} />
      )
      }
      {editModal && <EditOfferingModal product={product} isModalOpen={editModal} modalHandler={editHandler} dataRefresher={dataRefresher} />}
      {activateModal && (
        <OfferingActionModal
          actions={{
            onCancel: () => activateHandler(),
            onConfirm: () => activateOrDeactivate(),
          }}
          data={{
            operation: product.enabled ? "delete" : "edit",
            title: product.enabled ? `${t("Are you sure you want to deactivate")} "${product.title}"?` : `${t("Want to activate")} "${product.title}"?`,
            content: product.enabled ? (product?.productType === 'product' ? t("Deactivate removes the ability of Athletes to Purchase this offering.") : t("Deactivate removes the ability of Athletes to Purchase this offering.  Auto renewals will not be turned off. You can cancel active subscriptions manually if needed.")) :
              t("This offering will become purchasable by Athletes."),
            button_text: product.enabled ? t('Yes, deactivate it') : t('Yes, activate it')
          }}
        />
      )
      }
    </>
  );
}

