import React from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import RemainingSessions from "./RemainingSessions";

interface Offering {
  offeringTitle: string;
  remainingSessions: number;
}

interface OfferingCardProps {
  subscription: Offering;
  remaining?: number;
  updateRemainingSessions?: (
    purchaseId: string,
    incrementValue: number
  ) => void;
  purchaseId?: string;
  isCoach?: boolean;
}

export default function OfferingCard({
  subscription,
  remaining,
  updateRemainingSessions,
  purchaseId,
  isCoach = false,
}: OfferingCardProps) {
  const { t } = useTranslation();

  return (
    <div
      className={
        isCoach ? styles.subscriptionCardCoach : styles.subscriptionCard
      }
    >
      <div
        className={`${styles.iconWrapper2} ${
          isCoach ? styles.iconWrapperMargin : ""
        }`}
      >
        <i className="tab-icon ico ico-dollar-circle" />
      </div>
      <div
        className={`${styles.details} ${
          isCoach ? styles.detailsCoach : styles.detailsAthlete
        }`}
      >
        <p className={styles.subscriptionName}>{subscription.offeringTitle}</p>
        <div className={styles.subscriptionRow}>
          <p className={styles.subscriptionDetails}>{t("One time payment")}</p>
        </div>
      </div>

      {isCoach &&
        updateRemainingSessions &&
        purchaseId &&
        remaining !== undefined && remaining !== null && (
          <div className={styles.remainingSessionsContainer}>
            <RemainingSessions
              remaining={remaining}
              updateRemainingSessions={updateRemainingSessions}
              purchaseId={purchaseId}
            />
          </div>
        )}
    </div>
  );
}
