import React from "react";
import styles from "./styles.module.scss";
import SessionSvg from "../../../../../public/images/session.svg";
import { useTranslation } from "react-i18next";

interface NumberOfSessionsProps {
  numberOfSessions: number | null;
  unlimited: boolean;
}

const NumberOfSessions: React.FC<NumberOfSessionsProps> = ({
  numberOfSessions,
  unlimited,
}) => {
  const { t } = useTranslation();

  if (numberOfSessions == null && !unlimited) {
    return null;
  }

  return (
    <span className={styles.product_session}>
      <img src={SessionSvg} alt="tags" className={styles.card_icon} />
      {unlimited ? (
        <div>&infin; {t("Sessions")}</div>
      ) : (
        t("count_sessions", { count: numberOfSessions ?? 0 })
      )}
    </span>
  );
};

export default NumberOfSessions;
