/**
 * @module Components.Routes
 *
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "typedefs";
import { schedulingFetchGet } from "app/utils/request/scheduling/fetch";
import styles from "./styles.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import Offerings from "./Offerings";
import Setup from "./Setup";
import Customers from "./Customers";
import Navbar from "app/components/Navbar";
import { useTranslation } from "react-i18next";

export default function Billing() {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const userId = useSelector((state: RootState) => state.user.self.id);
  const history = useHistory();
  const [stripeId, setStripeId] = useState(null);
  const [activatedAccount, setActivatedAccount] = useState(null);
  const [enabledIntegration, setEnabledIntegration] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsLoadingData(true);
    const fetchCoachProfile = async () => {
      try {
        const profileData = await schedulingFetchGet(`coach_profiles/${userId}`);
        setStripeId(profileData.stripeAccountId);
        setEnabledIntegration(profileData.stripeIntegrationEnabled);
        setActivatedAccount(profileData.stripeAccountActive);
        if (profileData.stripeAccountActive === false || !profileData.stripeAccountId) {
          setSelectedTab(2);
        }
      } catch (error) {
        console.error("Error fetching coach profile:", error);
      } finally {
        setIsLoadingData(false);
      }
    };
    fetchCoachProfile();
  }, [userId, selectedTab]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tab = urlParams.get('tab');
    if (tab === 'setup') {
      setSelectedTab(2);
    }
    if (tab  === 'customers') {
      setSelectedTab(1);
    }
  }, [location, history]);

  useEffect(() => {
    if (location.state?.openCustomersTab) {
      setSelectedTab(1);
      history.replace({ ...location, state: {} });
    }
  }, [location, history]);

  const renderTab = (selectedTab) => {
    if(selectedTab === 0 ) {
      return <Offerings enabledIntegration={enabledIntegration}/>;
    }
    if(selectedTab === 1 ) {
      return <Customers />;
    }
    return (
    <Setup isLoadingData={isLoadingData} stripeId={stripeId} setStripeId={setStripeId} activatedAccount={activatedAccount}
                  enabledIntegration={enabledIntegration} setEnabledIntegration={setEnabledIntegration}/>
    );
  };

  return (
    <div className={styles.position}>
      <Navbar label={t("Billing")} hasSearch={false} hasNotifications={false} />
      <div className={`${styles.empty_container} billing-page-container`}>
        <div>
          <div className={styles.tabs_container}>
            <nav className={styles.navbar}>
              <ul className={styles.navList}>
              {stripeId  && activatedAccount ? (
              <li
                  className={`${styles.navItem} ${
                    selectedTab === 0 && styles.active
                  }`}
                  onClick={() => setSelectedTab(0)}
                >
                  {`Offerings`}
                </li>
                ) : null}
                {stripeId && activatedAccount ? (
                  <li
                    className={`${styles.navItem} ${
                      selectedTab === 1 && styles.active
                    }`}
                    onClick={() => setSelectedTab(1)}
                  >
                    {`Customers`}
                  </li>
                ) : null}
                <li
                  className={`${styles.navItem} ${
                    selectedTab === 2 && styles.active
                  }`}
                  onClick={() => setSelectedTab(2)}
                >
                  {`Setup`}
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {renderTab(selectedTab)}
      </div>
    </div>
  );
}
