import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { generateCurrencySymbol } from "app/routes/BuildProgram/Pricing/utils";
import OfferingActionModal from "../../OfferingActionModal";
import { schedulingFetchDelete } from "app/utils/request/scheduling/fetch";
import { toast } from "react-toastify";
import RemainingSessions from "./RemainingSessions";

interface Subscription {
  fullName?: string;
  profilePicture: string;
  offeringTitle: string;
  billingFrequency: number | null;
  nextInvoiceDate: string | null;
  productPrice: number;
  currency: string;
  remainingSessions: number | null;
  unlimited?: boolean;
  productType?: string;
  productId?: string;
  coachId?: string;
  studentProfileId?: string;
}

interface SubscriptionCardProps {
  subscription: Subscription;
  formatInvoiceDate: (isoDate: string) => string;
  handleDelete: (productId: string) => void;
  remaining?: number;
  updateRemainingSessions?: (
    purchaseId: string,
    incrementValue: number
  ) => void;
  purchaseId?: string;
  isCoach?: boolean;
}

export default function SubscriptionCard({
  subscription,
  formatInvoiceDate,
  handleDelete,
  remaining,
  updateRemainingSessions,
  purchaseId,
  isCoach = false,
}: SubscriptionCardProps) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    const productData = {
      coach_id: subscription.coachId,
      stripe_product_id: subscription.productId,
      student_profile_id: subscription.studentProfileId,
    };

    schedulingFetchDelete(`/stripe_purchase`, productData)
      .then((_) => {
        toast.success(t("Subscription canceled"));
        setShowModal(false);
        handleDelete(subscription.productId || "");
      })
      .catch((_) => {
        toast.error(t("An unexpected error has occurred."));
        setShowModal(false);
      });
  };

  const formattedDate = subscription?.nextInvoiceDate
    ? formatInvoiceDate(subscription?.nextInvoiceDate)
    : t("N/A");

  const modalContent = (
    isCoach ? (
      <div>
        {t("Are you sure you want to cancel the athlete's")}{" "}
        <strong style={{ color: "grey" }}>{subscription?.offeringTitle}</strong>{" "}
        {t("subscription?")}
        <br />
        {t("If you cancel, they won’t be charged at the next billing cycle.")}
        <br />
        {t("It will remain valid until")}{" "}
        <strong style={{ color: "grey" }}>{formattedDate}</strong>.
      </div>
    ) : (
      <div>
        {t("Are you sure you want to cancel your")}{" "}
        <strong style={{ color: "grey" }}>{subscription?.offeringTitle}</strong>{" "}
        {t("subscription?")}
        <br />
        {t("If you cancel, you won’t be charged at the next billing cycle.")}
        <br />
        {t("It will remain valid until")}{" "}
        <strong style={{ color: "grey" }}>{formattedDate}</strong>.
      </div>
    )
  );

  return (
    <div
      className={
        isCoach ? styles.subscriptionCardCoach : styles.subscriptionCard
      }
    >
      <div
        className={`${styles.iconWrapper} ${isCoach ? styles.iconWrapperMargin : ""
          }`}
      >
        <i className="fas fa-sync" />
      </div>
      <div
        className={`${styles.details} ${isCoach ? styles.detailsCoach : styles.detailsAthlete
          }`}
      >
        <div className={styles.subscriptionRowWithSessions}>
          <div>
            <p className={styles.subscriptionName}>
              {subscription.offeringTitle}
            </p>
            <div className={styles.subscriptionRow}>
              <p className={styles.subscriptionDetails}>
                {t("Billing {{billingFrequency}}", {
                  billingFrequency:
                    Number(subscription?.billingFrequency) === 1
                      ? t("monthly")
                      : `${subscription.billingFrequency} ${t("months")}`,
                })}
              </p>
              <span className={styles.dotSeparator}>•</span>
              <p className={styles.subscriptionRenewal}>
                {t(
                  "Auto renewal: next invoice on {{date}} for {{currency}}{{amount}}",
                  {
                    date: formatInvoiceDate(
                      subscription?.nextInvoiceDate || ""
                    ),
                    currency: generateCurrencySymbol(subscription.currency),
                    amount: subscription.productPrice,
                  }
                )}
              </p>
            </div>
          </div>
        </div>
      </div>

      {isCoach &&
        updateRemainingSessions &&
        purchaseId !== undefined ? (
        <div className={styles.remainingSessionsContainer}>
          {remaining !== undefined && remaining !== null ? (
            <RemainingSessions
              remaining={remaining}
              updateRemainingSessions={updateRemainingSessions}
              purchaseId={purchaseId}
            />
          ) : (
            <div className={styles.empty} />
          )}
        </div>
      ) : (
        <div className={styles.emptySessionContainer} />
      )}


      {subscription.nextInvoiceDate && (
        <button className={styles.deleteButton} onClick={handleDeleteClick}>
          <i className="fas fa-times" />
        </button>
      )}

      {showModal && (
        <OfferingActionModal
          actions={{
            onCancel: handleCancel,
            onConfirm: handleConfirm,
          }}
          data={{
            title: t("Are you sure you want to cancel subscription?"),
            content: modalContent,
            button_text: t("Yes, cancel it"),
            operation: "delete",
          }}
          cancelButton={t("No, keep it")}
        />
      )}
    </div>
  );
}
